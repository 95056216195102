import axios from 'axios'

export const baseURL = process.env.REACT_APP_BASE_URL
// export const baseURL = "https://masterprocessingvip.ru/api"

export var api

export const createAPI = () => {
  api = axios.create({
    baseURL
  })
  // const onSuccess = (response) => response

  // api.interceptors.response.use(onSuccess)

  // const requestHandler = (request) => {
  //   return request
  // }
  // api.interceptors.request.use((request) => requestHandler(request))

  return api
}

export const API = createAPI()
