import React, { useEffect, useState } from 'react'

import { API } from '../../api/api'
import { Endpoint, PaymentStatus } from '../../consts/consts'
import { useFetchData } from '../../hooks/use-fetch-data'
import useInterval from '../../hooks/use-interval'
import PaymentDetails from '../payment-details/payment-details'
import {
  BackButton,
  Button,
  CardContainer,
  CardIconsLabel,
  Form,
  FormContainer,
  Input,
  Title,
  IconsContainer,
  InputIcons,
  MainTitle,
  Container,
  CardsTip
} from './payment-form-styles'

import { MasterCard, MIR, PciDcc, VISA } from '../../icons/icons'

import Option from '../option/option'
import PaymentLinks from '../payment-links/payment-links'
import TextSpinner from '../text-spinner/text-spinner'
import PaymentFormH2H from '../payment-form-h2h/payment-form-h2h'
import {
  ErrorMessage,
  ExpireBlock,
  PendingBlock,
  SuccessBlock
} from '../payment-form-h2h/payment-form-styles'

export const Processing = {
  QIWI: `qw`,
  CARD: `card`,
  H2H: `h2h`,
  LIST: `list`
}

const options = [
  {
    label: `QIWI`,
    value: 1,
    name: `QWRUB`,
    fee: `5%`,
    paySource: Processing.QIWI,
    code: `qw`
  },
  {
    label: `Банковская карта`,
    value: 2,
    name: `CARD`,
    fee: `4%`,
    paySource: Processing.CARD,
    code: `card`
  }
]

const SourceLabel = {
  qw: `Киви кошелек`,
  card: `Банковская карта`,
  h2h: `Карта H2H`
}

export const urlParameters = window.location.pathname.includes(`finally`)
  ? window.location.search.slice(5)
  : window.location.pathname.slice(1)

// const paymentDetails.statusName = `Обработка платежа`

export default function PaymentForm() {
  const [UID] = useState({ UID: urlParameters })
  const {
    state: paymentDetails,
    setTrigger,
    isLoading
  } = useFetchData(Endpoint.GET_INVOICE_FORM_DETAILS, UID)
  const [, setLinkStatus] = useState({
    cause: '',
    success: null
  })

  const [tail, setTail] = useState(``)

  const [paySource, setPaySource] = useState(``)
  const [isLinksLoading, setIsLinksLoading] = useState(false)
  const [sourceFiltersState, setSourceFiltersState] = useState(null)

  useInterval(() => setTrigger((state) => !state), 1000, isLoading)

  const submitPaymentHandler = async (evt) => {
    evt.preventDefault()
    setIsLinksLoading(true)

    try {
      const { data } = await API({
        method: `POST`,
        url: Endpoint.GENERATE_INVOICE_BY_FORM,
        data: {
          tail: paySource === Processing.CARD ? tail : null,
          UID: UID.UID,
          paySource,
          pan: null,
          cvv: null,
          month: null,
          year: null
        }
      })
      setTail(``)
      setPaySource(``)
      setTrigger((state) => !state)
      setLinkStatus(data)
    } catch (err) {
      console.log(`err `, err)
    }
    setIsLinksLoading(false)
  }

  useEffect(() => {
    if (!sourceFiltersState) {
      getAllowPaySourcesFilter()
    }
  }, [paymentDetails, sourceFiltersState])

  useEffect(() => {
    if (paymentDetails?.selectedPaySourceFilter === Processing.H2H) {
      setPaySource(Processing.H2H)
    }
  }, [paymentDetails])

  useEffect(() => {
    if (
      paymentDetails &&
      paymentDetails?.statusName === PaymentStatus.PAYED &&
      paymentDetails?.redirectURL
    ) {
      window.location.replace(paymentDetails?.redirectURL)
    }
  }, [paymentDetails])

  const getAllowPaySourcesFilter = () => {
    if (paymentDetails && paymentDetails.allowPaySourcesFilter) {
      const paySources = paymentDetails.allowPaySourcesFilter
      if (!paySources.includes(',')) {
        if (paySources.includes(Processing.QIWI)) {
          setPaySource(Processing.QIWI)
          return setSourceFiltersState(Processing.QIWI)
        }
        if (paySources.includes(Processing.CARD)) {
          setPaySource(Processing.CARD)
          return setSourceFiltersState(Processing.CARD)
        }
        if (paySources.includes(Processing.H2H)) {
          setPaySource(Processing.H2H)
          return setSourceFiltersState(Processing.H2H)
        }
      }

      return setSourceFiltersState(Processing.LIST)
    }
  }

  const mapSourcesList = () => {
    const paySourcesList = paymentDetails.allowPaySourcesFilter.split(`,`)
    return paySourcesList.map((item, idx) => {
      return (
        <Option
          key={idx}
          name={item}
          label={SourceLabel[item]}
          paymentDetails={paymentDetails}
          setPaySource={setPaySource}
          paySource={paySource}
        />
      )
    })
  }

  if (
    paySource === Processing.H2H &&
    paymentDetails &&
    paymentDetails.statusName !== PaymentStatus.EXPIRED
  ) {
    return (
      <PaymentFormH2H
        paymentDetails={paymentDetails}
        paySource={paySource}
        setPaySource={setPaySource}
        sourceFiltersState={sourceFiltersState}
      />
    )
  }

  return (
    <Container>
      <MainTitle>
        {paymentDetails &&
          paymentDetails.displaySettings &&
          paymentDetails.displaySettings.header &&
          paymentDetails.displaySettings.header}
      </MainTitle>
      {!paymentDetails?.paymentFlow ? (
        <Title width='100%'>Пожалуйста выберите способ оплаты</Title>
      ) : (
        <>
          <Title maxWidth='540px'>
            Оплатите заявку, используя указанные ниже платежные ссылки.{' '}
            <br></br>
            {paymentDetails &&
              paymentDetails.selectedPaySourceFilter === Processing.CARD &&
              `Используйте для оплаты карту, указанную вами на предыдущей странице!`}
          </Title>
        </>
      )}
      {paymentDetails &&
        paymentDetails.selectedPaySourceFilter === Processing.CARD && (
          <CardsTip>
            Внимание - на следующей странице укажите полный номер карты. Для
            успешного прохождения платежа не меняйте номер карты
          </CardsTip>
        )}
      <TextSpinner
        isLinksLoading={isLinksLoading}
        paymentDetails={paymentDetails}
      />
      <FormContainer>
        {paySource &&
          sourceFiltersState === Processing.LIST &&
          paymentDetails.statusName !== PaymentStatus.EXPIRED && (
            <BackButton onClick={() => setPaySource(``)} />
          )}

        <Form onSubmit={submitPaymentHandler}>
          {!paySource &&
          paymentDetails &&
          paymentDetails.allowPaySourcesFilter &&
          !paymentDetails.paymentFlow &&
          sourceFiltersState &&
          sourceFiltersState === Processing.LIST &&
          paymentDetails.statusName === PaymentStatus.START
            ? mapSourcesList()
            : null}

          {paymentDetails && paymentDetails.paymentFlow ? (
            <PaymentLinks
              paymentDetails={paymentDetails}
              isLoading={isLoading}
            />
          ) : null}
          {paySource === Processing.CARD &&
          !paymentDetails?.selectedPaySourceFilter ? (
            <>
              <CardIconsLabel>Последние 4 цифры карты</CardIconsLabel>
              <CardContainer>
                <Input
                  placeholder='3550'
                  required
                  type='text'
                  minLength='4'
                  value={tail}
                  onChange={({ target }) => setTail(target.value)}
                />
                <InputIcons />
              </CardContainer>
              <Button
                onClick={submitPaymentHandler}
                type='button'
                disabled={tail.length < 4 || isLinksLoading}
              >
                Оплатить {paymentDetails && paymentDetails.amount} ₽
              </Button>
            </>
          ) : null}

          {paySource === Processing.QIWI &&
          !paymentDetails?.selectedPaySourceFilter ? (
            <Button
              onClick={submitPaymentHandler}
              disabled={isLinksLoading}
              type='button'
            >
              Нажмите для оплаты
            </Button>
          ) : null}

          {paymentDetails &&
          paymentDetails.statusName === PaymentStatus.PENDING ? (
            <PendingBlock>
              <div></div>
            </PendingBlock>
          ) : null}
          {paymentDetails &&
          paymentDetails.statusName === PaymentStatus.PAYED ? (
            <SuccessBlock />
          ) : null}
          {paymentDetails &&
          paymentDetails.statusName === PaymentStatus.EXPIRED ? (
            <>
              <div>
                <ErrorMessage>Время на оплату вышло</ErrorMessage>
              </div>
              <ExpireBlock />
            </>
          ) : null}
          {paymentDetails &&
          paymentDetails.statusName === PaymentStatus.DECLINED ? (
            <>
              <div>
                <ErrorMessage>
                  Транзакция отклонена. Попробуйте пересоздать оплату и оплатить
                  с помощью другой карты.
                </ErrorMessage>
              </div>
              <ExpireBlock />
            </>
          ) : null}
        </Form>
        {paymentDetails &&
        paymentDetails &&
        paymentDetails?.statusName !== PaymentStatus.EXPIRED ? (
          <PaymentDetails
            paymentDetails={paymentDetails}
            isLoading={isLinksLoading}
          />
        ) : null}
        <IconsContainer>
          <PciDcc />
          <MasterCard />
          <VISA />
          {/* <MIR /> */}
        </IconsContainer>
      </FormContainer>
    </Container>
  )
}
