import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  min-width: 50%;
  max-width: 50%;

  @media (max-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

export const PaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 32px;

  span {
    margin-top: 20px;
    font-size: 18px;
    line-height: 28px;
  }

  strong {
    font-size: 36px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    padding: 10px;
    width: max-content;
    margin-bottom: 0;
  }
`

export const InvoiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  label {
    font-weight: 400;
    color: rgb(162, 162, 163);
    font-size: 16px;
    line-height: 25px;
  }
  span {
    line-height: 28px;
    font-size: 18px;
    color: rgb(37, 33, 41);
  }
`

export const CountDownBlock = styled.div`
  position: absolute;
  color: rgb(162, 162, 163);
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;

  @media (max-width: 768px) {
    top: 140px;
  }
`

export const Help = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: auto;
`

export const PaymentList = styled.div`
  margin-bottom: 30px;
`

export const PaymentLink = styled.a`
  border-radius: 16px;
  padding: 8px;
  border: 2px solid #eee;
  color: #fff;
  display: block;
  width: 200px;
  text-align: center;
  background-color: ${({ payed }) => (payed ? `green` : `blue`)};

  :hover {
    opacity: 0.8;
    /* background-color: rgba(238, 238, 238, 0.6);
    background-color: ${({ payed }) =>
      payed ? `rgba(48, 174, 144, 0.6)` : `rgba(238, 238, 238, 0.6)`}; */
  }
`

export const ListTitle = styled.h4`
  position: relative;
  display: block;
  z-index: 3;
  background-color: transparent;
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  background-image: ${({ payed }) =>
    payed
      ? ``
      : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='20' height='20' viewBox='0 0 980.000000 980.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,980.000000) scale(0.100000,-0.100000)' fill='%23000000' stroke='none'%3E%3Cpath d='M4625 9794 c-468 -30 -943 -126 -1345 -271 -1235 -447 -2179 -1276 -2749 -2413 -239 -478 -383 -914 -465 -1410 -49 -296 -60 -439 -60 -800 0 -430 29 -706 115 -1085 170 -750 556 -1519 1059 -2110 128 -151 399 -419 555 -549 453 -377 969 -671 1547 -880 745 -268 1600 -343 2418 -210 1008 163 2010 686 2717 1418 475 491 848 1092 1092 1760 135 369 221 743 268 1166 24 217 24 763 0 980 -67 606 -210 1104 -476 1655 -521 1081 -1374 1887 -2506 2370 -437 187 -906 308 -1395 360 -138 15 -644 27 -775 19z m715 -909 c476 -55 883 -176 1320 -391 806 -395 1400 -978 1799 -1764 140 -277 221 -478 296 -738 177 -613 201 -1276 69 -1907 -136 -651 -472 -1327 -910 -1830 -421 -484 -990 -872 -1639 -1117 -829 -313 -1793 -327 -2640 -38 -924 316 -1679 917 -2154 1715 -317 534 -496 1054 -568 1655 -24 202 -24 658 0 860 59 494 177 893 396 1336 291 591 667 1050 1168 1426 191 143 387 263 623 381 517 259 1009 394 1565 431 124 8 545 -4 675 -19z'/%3E%3Cpath d='M4811 8010 c-178 -38 -322 -190 -351 -370 -13 -88 -13 -2709 0 -2805 6 -38 24 -99 41 -135 28 -60 84 -119 752 -787 845 -845 774 -788 982 -788 114 0 123 2 187 32 161 76 258 229 258 407 0 82 -20 160 -57 226 -16 28 -268 288 -649 670 l-623 625 -3 1285 c-4 1444 3 1327 -80 1450 -48 72 -132 139 -214 170 -64 24 -179 33 -243 20z'/%3E%3C/g%3E%3C/svg%3E");`};
  background-repeat: no-repeat;
  background-position: 150px 0;

  ::after {
    position: absolute;
    display: ${({ payed }) => (payed ? `block` : `none`)};
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #2eab8e;
    z-index: 1;
    top: 0;
    left: 150px;
  }
  ::before {
    position: absolute;
    display: ${({ payed }) => (payed ? `block` : `none`)};
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 17.837 17.837'%0Awidth='14'%0Aheight='14'%0A%3E%3Cg%3E%3Cpath style='fill:%23fff;' d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z' /%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 3;
    top: 0;
    left: 150px;
  }
`

export const LinksBlock = styled.div`
  position: relative;
  margin-top: 20px;
`
