import React, { useRef, useState } from 'react'

import { API } from '../../api/api'
import { Endpoint, PaymentStatus } from '../../consts/consts'
import { useFetchData } from '../../hooks/use-fetch-data'
import useInterval from '../../hooks/use-interval'
import Spinner from '../spinner/spinner'
import PaymentDetails from '../payment-details/payment-details'
import {
  Button,
  CardContainer,
  Form,
  FormContainer,
  Input,
  Title,
  Container,
  StyledInput,
  CardIcons,
  Header,
  ErrorMessage,
  SuccessBlock,
  ExpireBlock,
  PendingBlock,
  BackButton,
  CommonInput
} from './payment-form-styles'
import { useEffect } from 'react'
import { Processing } from '../payment-form/payment-form'
import InputMask from '../input-mask/input-mask'

// const paymentDetails = {
//   statusName: PaymentStatus.INITIAL,
//   amount: 1000,
//   comment: "comment",
//   expireAt: 166900022,
//   fee: 21
// }

// paymentDetails.statusName
export default function PaymentFormH2H({ paymentDetails, paySource, setPaySource, sourceFiltersState }) {
  const formRef = useRef()
  const threeDSLinkRef = useRef()

  const urlParameters = window.location.pathname.includes(`finally`)
    ? window.location.search.slice(5)
    : window.location.pathname.slice(1)

  const [cardNumber, setCardNumber] = useState(``)
  const [cardExpirationDate, setCardExpirationDate] = useState(``)
  const [CVVCode, setCVVCode] = useState(``)
  const [isSubmitting, setIsSubmitting] = useState(false)


  const [errorMessage, setErrorMessage] = useState(``)

  const [confirmationData, setConfirmationData] = useState({
    paReq: ``,
    md: ``,
    termUrl: ``,
    redirectUrl: ``
  })
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  function sendPostToUrl(md, paReq, termUrl) {

    formRef.current.append('MD', md);
    formRef.current.append('PaReq', paReq);
    formRef.current.append('TermUrl', termUrl);

    formRef.current.submit();
  }

  const submitPaymentHandler = async (evt) => {
    evt.preventDefault()
    setIsSubmitLoading(true)
    let month;
    let year;
    if (cardExpirationDate.length &&
      cardExpirationDate.length === 7 &&
      cardExpirationDate[0] === '2' &&
      cardExpirationDate[1] === '0') {
      month = cardExpirationDate.slice(5)
      year = cardExpirationDate.slice(0, 4)
    } else {
      month = `${cardExpirationDate.slice(0, 2)}`
      year = `20${cardExpirationDate.slice(3)}`
    }
    try {
      const { data } = await API({
        method: `POST`,
        url: Endpoint.GENERATE_INVOICE_BY_FORM,
        data: {
          pan: cardNumber.split(` `).join(``),
          cvv: CVVCode,
          month,
          year,
          UID: urlParameters,
          paySource,
          tail: null
        }
      })
      const { start3DsResponse, success } = data

      setConfirmationData(start3DsResponse.transaction.confirmationData)

      if (!data) {
        setErrorMessage("Ошибка")
        setIsSubmitLoading(false)
        return
      }

      if (!start3DsResponse.success) {
        setErrorMessage(start3DsResponse?.transaction?.userMessage || "Попробуйте другие реквизиты")
        setIsSubmitLoading(false)
        return
      }
      // debugger
      setTimeout(() => {
        // debugger
        sendPostToUrl(
          start3DsResponse.transaction.confirmationData.md,
          start3DsResponse.transaction.confirmationData.paReq,
          start3DsResponse.transaction.confirmationData.termUrl
        )
      }, 300);

    } catch (err) {
      console.log(`err `, err)
      setIsSubmitLoading(false)
    }
  }

  // useEffect(() => {
  //   if (paymentDetails) {
  //     setIsSubmitLoading(false)
  //   }
  // }, [paymentDetails])

  useEffect(() => {

    if (paymentDetails?.threeDsResult?.success && !window.location.pathname.includes(`finally`)) {
      setConfirmationData(paymentDetails.threeDsResult.transaction.confirmationData)
      setTimeout(() => {

        sendPostToUrl(
          paymentDetails.threeDsResult.transaction.confirmationData.md,
          paymentDetails.threeDsResult.transaction.confirmationData.paReq,
          paymentDetails.threeDsResult.transaction.confirmationData.termUrl
        )
      }, 0);

    }
  }, [paymentDetails])

  return (
    <Container>
      <Header>MPCVip</Header>
      {(isSubmitLoading) && <Spinner />}
      <FormContainer style={{ height: paymentDetails && paymentDetails.statusName === PaymentStatus.DECLINED && `400px` }}>
        {paySource &&
          sourceFiltersState === Processing.LIST &&
          !paymentDetails?.threeDsResult?.success &&
          <BackButton onClick={() => setPaySource(``)} />}
        <Form
          onSubmit={submitPaymentHandler} onChange={() => {
            setErrorMessage(``)
          }}>

          {paymentDetails &&
            (paymentDetails.statusName === PaymentStatus.INITIAL || paymentDetails.statusName === PaymentStatus.START) &&
            !paymentDetails?.threeDsResult?.success ?
            <fieldset style={{ all: `inherit` }} disabled={isSubmitLoading}>
              <Title>Оплатить заявку</Title>
              <CardContainer>
                <CardIcons />
                <Input
                  autoFocus
                  placeholder='Номер карты'
                  required
                  mask='0000 0000 0000 0000'
                  type='text'
                  minLength='16'
                  maxLength='24'
                  autoComplete='cc-number'
                  value={cardNumber}
                  style={{ marginBottom: `12px` }}
                  onChange={({ target }) => {
                    target.value.length === 19 && document.getElementById('cardExpirationDate').focus()
                    setCardNumber(target.value)
                  }}
                />
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    gap: `10px`,
                    marginBottom: `12px`
                  }}
                >
                  <CommonInput
                    id="cardExpirationDate"
                    placeholder='ММ/ГГ'
                    autoComplete='cc-exp'
                    required
                    mask='00/00'
                    type='text'
                    minLength='5'
                    maxLength='5'
                    value={cardExpirationDate}
                    onKeyDown={(evt) => {
                      const { target, key } = evt
                      if (key === 'Backspace' && target.value.length === 3) {
                        setCardExpirationDate(target.value.slice(0, 2))
                      }
                    }}
                    onChange={({ target }) => {
                      target.value.length === 5 && document.getElementById('CVVRef').focus()
                      if (target.value.length === 2) {
                        setCardExpirationDate(target.value + '/')
                      } else {
                        setCardExpirationDate(target.value)
                      }
                      console.log(`exp `, target.value.length);
                    }}
                    onPaste={(evt) => {
                      let paste = (evt.clipboardData || window.clipboardData).getData(
                        'text'
                      )
                      if (paste.length === 4) {
                        setCardExpirationDate(paste.slice(0, 2) + '/' + paste.slice(2))
                      }
                    }} />
                  {/* <StyledInput
                    id="cardExpirationDate"
                    placeholder='ММ/ГГ'
                    autoComplete='cc-exp'
                    required
                    mask='00/00'
                    type='text'
                    minLength='16'
                    maxLength='24'
                    value={cardExpirationDate}

                    onChange={({ target }) => {
                      target.value.length === 5 && document.getElementById('CVVRef').focus()
                      // if (target?.value && target.value?.length && target.value[0] !== '0') {
                      //   console.log(`target.value `, target.value);
                      //   setCardExpirationDate(`0` + target.value + 1)
                      // } else {
                      // }
                      setCardExpirationDate(target.value)
                      console.log(`exp `, target.value.length);
                    }}
                  /> */}
                  <StyledInput
                    id="CVVRef"
                    placeholder='CVV/CVC'
                    required
                    mask='000'
                    type='password'
                    minLength='3'
                    maxLength='3'
                    autoComplete='cc-csc'
                    value={CVVCode}
                    onChange={({ target }) => {
                      setCVVCode(target.value)
                    }}
                  />
                </div>
              </CardContainer>
              <Button disabled={isSubmitLoading}>
                Оплатить
              </Button>
            </fieldset> : null}

          {paymentDetails && paymentDetails?.threeDsResult?.success ? <PendingBlock><div></div></PendingBlock> : null}
          {paymentDetails && paymentDetails.statusName === PaymentStatus.PAYED
            ? <SuccessBlock /> : null}
          {paymentDetails && paymentDetails.statusName === PaymentStatus.EXPIRED
            ? <><div>
              <ErrorMessage>Время на оплату вышло</ErrorMessage>
            </div>
              <ExpireBlock /></> : null}
          {paymentDetails && paymentDetails.statusName === PaymentStatus.DECLINED
            ? <><div>
              <ErrorMessage>Транзакция отклонена. Попробуйте пересоздать оплату и оплатить с помощью другой карты.</ErrorMessage>
            </div>
              <ExpireBlock /></>
            : null}
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </Form>
        <form
          ref={formRef}
          encType="application/x-www-form-urlencoded"
          action={confirmationData?.redirectUrl}
          method="POST"
          style={{ fontSize: 0 }}
        >
          <input type="hidden" name="PaReq" value={confirmationData?.paReq} />
          <input type="hidden" name="MD" value={confirmationData?.md} />
          <input type="hidden" name="TermUrl" value={confirmationData?.termUrl} />
          <a ref={threeDSLinkRef} onClick={() => sendPostToUrl(confirmationData?.paReq, confirmationData?.md, confirmationData?.termUrl)}></a>
        </form>
        {paymentDetails && paymentDetails ? (
          <PaymentDetails
            paymentDetails={paymentDetails}
            isLoading={isSubmitLoading}
          />
        ) : null}

      </FormContainer>
    </Container>
  )
}
